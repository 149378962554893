import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Twitter, Instagram, Facebook, Youtube, Email } from './icons'

class SocialBar extends Component {

  state = { 
    result: '',
    msg: ''
  };

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  // 2. via `async/await`
  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email)
    // I recommend setting `result` to React state
    // but you can do whatever you want
    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {
    let { data } = this.props

    return (
      <div className={this.props.className ? 'social-bar social-bar--full' : 'social-bar'}>
        <div className='social-bar__inner'>
          <form className='social-bar__subscribe' onSubmit={this._handleSubmit}>
            <h4>{ this.props.title }</h4>
            { this.state.result !== '' &&
              <div className="social-bar__form-wrapper">
                <p className="result" dangerouslySetInnerHTML={{ __html: this.state.msg }} />
              </div>
            }
            { this.state.result === '' &&
              <div className="social-bar__form-wrapper">
                <input type="email" onChange={this._handleChange} placeholder="Subscribe" name="email" />
                <button type='submit'>Add</button>
              </div>
            }
          </form>
          <div className='social-bar__links'>
            <a href={data.options.options.twitter} target='_blank' rel='nofollow noopener noreferrer'><Twitter color={'#000000'} /></a>
            <a href={data.options.options.instagram} target='_blank' rel='nofollow noopener noreferrer'><Instagram color={'#000000'} /></a>
            <a href={data.options.options.facebook} target='_blank' rel='nofollow noopener noreferrer'><Facebook color={'#000000'} /></a>
            <a href={data.options.options.youtube} target='_blank' rel='nofollow noopener noreferrer'><Youtube color={'#000000'} /></a>
            <a href='mailto:luke@the-father-hood.com' target='_blank' rel='nofollow noopener noreferrer'><Email color={'#000000'} /></a>
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        options: wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            twitter
            instagram
            facebook
            youtube
          }
        } 
      } 
    `}
    render={data => <SocialBar data={data} {...props} />}
  />
)
